@import './partials/colors';
@import './partials/normalize';
@import './partials/mixins';

// Components
@import './layout/footer/footer';
@import './layout/header/header';
@import './layout/container/container';
@import './layout/hero/hero';

// Pages
@import './pages/Home/home';
@import './pages/About/about';
@import './pages/Portfolio/portfolio';

@import 'mobile.scss';

body {
	background: $dark;
	color: $textColor;
}

.site-width {
	max-width: 1200px;
	margin: 0 auto;
	display: block;
}

.container {
	position: relative;
	width: 100%;
	
}

.pageWrapper {
	width: 100%!important;
	
}

.enter {
	transition: .5s all;
	opacity: .2;
	&.enter-active {
		position: relative;
		z-index: 2;
		opacity: 1;
	}
	&.enter-done {
		opacity: 1;
		position: relative;
	}
}

.exit {
	position: absolute;
	top: 0;
	opacity: 1;
	transition: .5s all;
	&.exit-active {
		position: absolute;
		z-index: 1;
		opacity: 0;
	}
	&.exit-done {
		position: absolute;
		
	}
}


.full-resume {
	text-align: center;
	padding: 10px;
	margin-bottom: 30px;
	a {
		color: #fff;
	}
}