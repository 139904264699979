@mixin flexCenter {
	display: flex;
	align-items: center;
	justify-content: center;
}

@mixin siteWidth($width) {
	width: 100%;
	display: block;
	margin: 0 auto;
	max-width: $width;
}