.pagePortfolio {
	@include siteWidth(1200px);
	display: flex;
	
	.nothing {
		text-align: center;
		button {
			display: block;
			margin: 15px auto;
			background: $secondary;
			border: none;
			padding: 10px;
			border-radius: 3px;
			font-size: .8em;
			cursor: pointer;
		}
	}
	

	  
	  
	.categories {
		width: 30%;
		display: block;
		padding: 10px;
		position: relative;
		.tags-title {
			text-align: center;
			text-transform: uppercase;
			font-weight: bold;
		}
		.tags-container{
			padding: 10px 0;
			min-height:100px;
			position: relative;

			display: flex;
			flex-wrap: wrap;
		}
		.category {
			width: calc(100% / 2 - 10px);
			display: block;
			margin: 5px;
			padding: 5px;
			background: $dark;
			cursor: pointer;
			border-radius: 3px;
			border: 1px solid #fff;
			transition: 0.25s all;
			text-align: center;
			&:hover {
				background: $secondary;
				border: 1px solid $secondary;
			}
			&.active {
				background: $secondary;
				border: 1px solid $secondary;
				color: $dark;
			}
		}
	}

	.categories .reset {
		@extend .category;
		width: calc(100% - 10px) ;
		text-align: center;
		display: block;
		margin: 10px auto;
		background: $secondary;
		border: 1px solid $secondary;
	}
	.portfolio-items {
		width: 70%;
		display: flex;
		align-items: flex-start;
		justify-content: center;
		flex-wrap: wrap;
		padding: 10px;
		position: relative;
		
		&.loading  {
			transition: 0.5s;
			.loading-animation{
				visibility: visible;
				opacity: 1;
			
			}
		}
		.loading-animation {
			position: absolute;
			left: 0;
			top: 0;
			z-index: 3;
			background: $dark;
			width: 100%;
			height: 100%;

			display: flex;
			align-items: center;
			justify-content: center;

			visibility: hidden;
			opacity: 0;
			
			.loader {
				position: relative;
				transform: none;
				left: auto;
				top: auto;
			}
		}

		.portfolio-item {
			width: calc(100% / 3 - 20px);
			margin: 10px;
			color: #fff;
			.portfolio-header {
				position: relative;
				.image {
					min-height: 250px;
					img {
						height: 250px;
						width: 100%;
						object-fit: cover;
					}
				}

				
				.portfolio-title {
					position: absolute;
					left: 50%;
					transform: translateX(-50%);
					bottom: 0;
					width: 100%;
					padding: 10px;
					text-align: left;
					background: rgba(#000, .5);
					font-weight: bold;
					text-transform: uppercase;
				}
			}

			.description {
				.tag {
					width: auto;
					display: inline-block;
					margin: 5px;
					padding: 5px;
					border-radius: 3px;
					&.active {
						background: $secondary;
					}
				}
			}
		}
	}
}

.individualPortfolio {
	min-height: 300px;
	position: relative;

	.individualPortfolio-body {
		@include siteWidth(1200px);
		position: relative;
	}

	.individualPortfolio-head {
		position: relative;
		overflow: hidden;
		&::before {
			content: '';
			z-index: 2;
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			background: rgba(#000, .5);
		}
		img{
			width: 100%;
			height: 100%;
			object-fit: cover;
			max-height: 300px;
			filter: blur(10px);
			
		}
		&-info {
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translateX(-50%) translateY(-50%);
			width: 100%;
			max-width: 1200px;
			text-align: center;
			z-index: 3;
		}

		.tags {
			span {
				margin: 5px;
				border-radius: 3px;
				padding: 5px;
				border: 1px solid #fff;
			}
		}
	}
}

.loader {
	display: inline-block;
	position: absolute;
	width: 80px;
	height: 80px;
	left: 50%;
	top: 50%;
	transform: translateX(-50%) translateY(-50%);
	transition: 0.25s all;
	div {
		position: absolute;
		top: 33px;
		width: 13px;
		height: 13px;
		border-radius: 50%;
		background: #fff;
		animation-timing-function: cubic-bezier(0, 1, 1, 0);
		&:nth-child(1) {
			left: 8px;
			animation: lds-ellipsis1 0.6s infinite;
		}

		&:nth-child(2) {
			left: 8px;
			animation: lds-ellipsis2 0.6s infinite;
		}

		&:nth-child(3) {
			left: 32px;
			animation: lds-ellipsis2 0.6s infinite;
		}

		&:nth-child(4) {
		left: 56px;
		animation: lds-ellipsis3 0.6s infinite;
		}
		@keyframes lds-ellipsis1 {
			0% {
			  transform: scale(0);
			}
			100% {
			  transform: scale(1);
			}
		  }
		  @keyframes lds-ellipsis3 {
			0% {
			  transform: scale(1);
			}
			100% {
			  transform: scale(0);
			}
		  }
		  @keyframes lds-ellipsis2 {
			0% {
			  transform: translate(0, 0);
			}
			100% {
			  transform: translate(24px, 0);
			}
		  }
	  }
  }