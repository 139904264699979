.footer {
	padding: 20px 10px;
	text-align: center;
	&:hover {
		.quote {
			color:lighten($dark, 30%) ;
		}
	}
	.quote {
		text-align: center;
		line-height: 1.5;
		color: rgba(lighten($dark, 30%), .5);
		transition: 0.25s all;
		span {
			display: block;
			font-weight: bold;
		}
	}

	.disclaimer {
		display: block;
		padding: 10px 0;
		p {
			margin: 5px 0;
		}
	}

	.links {
		display: block;
		ul {
			display: flex;
			align-items: center;
			justify-content: center;
			list-style-type: none;
			margin: 0;
			padding: 0;
			
			a {
				width: 25px;
				height: 25px;
				display: flex;
				align-items: center;
				justify-content: center;
				background: #fff;
				margin: 5px;
				border-radius: 3px;
				svg {
					color: $dark;
					
				}
			}
		}
	}
}