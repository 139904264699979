.hero {
	@include flexCenter;
	min-height: 500px;
	transition: 1.5s all;
	background-size: 100% auto;
	box-shadow: inset 999px 9999px rgba(#000, .7);
	background-position: center center;
	background-repeat: no-repeat;
	text-align: center;
	background-size: cover;
	h2 {
		font-size: 2.5em;
		margin: 0 auto;
	}
	&:hover {
		box-shadow: inset 999px 9999px rgba(#000, .5);
		// background-size: 105% auto;
	}
}